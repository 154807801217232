// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-templates-accessories-tsx": () => import("./../src/templates/accessories.tsx" /* webpackChunkName: "component---src-templates-accessories-tsx" */),
  "component---src-templates-configurator-tsx": () => import("./../src/templates/configurator.tsx" /* webpackChunkName: "component---src-templates-configurator-tsx" */),
  "component---src-templates-contact-form-tsx": () => import("./../src/templates/contact-form.tsx" /* webpackChunkName: "component---src-templates-contact-form-tsx" */),
  "component---src-templates-content-page-tsx": () => import("./../src/templates/content-page.tsx" /* webpackChunkName: "component---src-templates-content-page-tsx" */),
  "component---src-templates-read-more-tsx": () => import("./../src/templates/read-more.tsx" /* webpackChunkName: "component---src-templates-read-more-tsx" */),
  "component---src-templates-index-page-tsx": () => import("./../src/templates/index-page.tsx" /* webpackChunkName: "component---src-templates-index-page-tsx" */),
  "component---src-templates-test-drive-form-tsx": () => import("./../src/templates/test-drive-form.tsx" /* webpackChunkName: "component---src-templates-test-drive-form-tsx" */),
  "component---src-templates-dealer-locator-tsx": () => import("./../src/templates/dealer-locator.tsx" /* webpackChunkName: "component---src-templates-dealer-locator-tsx" */),
  "component---src-templates-product-tsx": () => import("./../src/templates/product.tsx" /* webpackChunkName: "component---src-templates-product-tsx" */)
}

